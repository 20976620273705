import React, { useEffect } from "react";
import styled from "styled-components";
import TitleGradient from "../Components/TitleGradient";

const Policy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <Contents>
        <TitleGradient
          title="이용약관"
          explain={"환영합니다.\nKUPhil 홈페이지를 이용해 주셔서 감사합니다."}
          link="/policy"
          color="linear-gradient(91.48deg, #FFF2E7 0%, rgba(254, 204, 190, 0.57) 100%)"
        />
        <StyledPolicy>
          <div>
            <div>
              쿠필 홈페이지는 여러분들이 쿠필과 관련된 정보를 쉽게 이용할 수
              있도록 다양한 기능, 서비스(이하 "서비스")를 제공합니다. 이
              약관에는 쿠필 홈페이지 서비스 이용에 필요한 중요한 내용이 담겨
              있습니다.
            </div>
            <PolicyContent>
              <div>
                <b>제1조 (목적)</b>
                <p>
                  본 약관은 쿠필(이하 ‘단체’)가 제공하는 쿠필 및 쿠필 관련 제반
                  서비스(이하 '서비스')의 이용과 관련하여 단체와 이용자의 권리,
                  의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                </p>
              </div>
              <div>
                <b>제2조 (이용자의 정의)</b>
                <p>
                  ① '이용자'란 본 약관에 동의하고 단체가 제공하는 서비스를
                  이용하는 자를 통칭합니다.
                </p>
              </div>
              <div>
                <b>제3조 (서비스의 제공)</b>
                <p>① 단체는 이용자에게 아래와 같은 서비스를 제공합니다.</p>
                <ul>
                  <li>커뮤니티 서비스 (Q&A 등)</li>
                  <li>쿠필 활동 기록 서비스 (연혁, 아카이브 등)</li>
                  <li>
                    기타 단체가 자체 개발하거나 다른 단체와의 협력 계약 등을
                    통해 이용자들에게 제공할 일체의 서비스
                  </li>
                </ul>
              </div>
              <div>
                <b>제4조 (서비스의 중단 및 변경)</b>
                <p>
                  ① 단체는 365일, 24시간 원활한 서비스 제공을 위해 최선을 다하고
                  있습니다. 다만, 컴퓨터 등 정보통신 설비의 보수 점검, 교체 및
                  고장, 통신 두절 등의 사유가 발생한 경우 일부 또는 전체
                  서비스의 제공을 제한하거나 중단할 수 있습니다. 또는, 제3조에서
                  제공하는 서비스를 운영 및 개선의 목적으로, 혹은 단체가
                  적절하다고 판단하는 사유에 기해 변경하거나 종료할 수 있습니다.
                </p>
                <p>
                  ② 단체는 제1항에 의한 일부 서비스 제한 및 중단에 대해 예측
                  가능한 경우 상당 기간 전에 이를 안내하며, 예측 불가능한
                  경우라면 지체 없이 상세히 설명하고 안내드리겠습니다. 또한,
                  서비스 종료의 경우에 단체는 제7조에서 정한 방법으로 이용자에게
                  통지합니다. 단, 단체가 통제할 수 없는 사유로 인한 서비스의
                  중단(시스템 관리자의 고의, 과실이 없는 디스크 장애, 시스템
                  다운 등)으로 인하여 사전 통지가 불가능한 경우에는 예외로
                  합니다. 이러한 경우에도 단체가 상황을 파악하는 즉시 최대한
                  빠른 시일 내에 서비스를 복구하도록 노력하겠습니다.
                </p>
              </div>
              <div>
                <b>제5조 (이용자에 대한 통지)</b>
                <p>
                  단체가 전체 이용자에 대한 통지를 하는 경우 7일 이상 메인
                  페이지에 게시함으로써 개별 통지에 갈음할 수 있습니다.
                </p>
              </div>
              <div>
                <b>제6조 (단체의 의무)</b>
                <p>
                  ① 단체는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를
                  하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로
                  서비스를 제공하기 위해서 노력합니다.
                </p>
                <p>
                  ② 단체는 이용자가 안전하고 편리하게 서비스를 이용할 수 있도록
                  시스템을 구축합니다. 또한, 단체의 서비스를 매개로 한 이용자
                  상호 간의 의견 교환, 거래 등에서 발생한 손해나 이용자가 서비스
                  상에 게재된 타인의 게시물 등의 콘텐츠를 신뢰함으로써 발생한
                  손해에 대해서도 단체는 특별한 사정이 없는 한 이에 대해 책임을
                  부담하지 않습니다.
                </p>
              </div>
              <div>
                <b>제7조 (Q&A 이용자의 게시글 비밀번호에 대한 의무)</b>
                <p>
                  ① 단체가 관계법령, ‘개인정보보호 정책’에 의해서 그 책임을 지는
                  경우를 제외하고, Q&A 질문 등록 시 기입한 자신의 비밀번호에
                  관한 관리 책임은 각 이용자에게 있습니다.
                </p>
                <p>
                  ② Q&A 이용자는 자신의 게시글 비밀번호를 제3자에게 이용하게
                  해서는 안 됩니다.
                </p>
                <p>
                  ③ Q&A 이용자는 자신의 게시글 비밀번호를 도난당하거나 제3자가
                  사용하고 있음을 인지한 경우에는 바로 단체에 통보하고 단체의
                  안내가 있는 경우에는 그에 따라야 합니다.
                </p>
              </div>
              <div>
                <b>제8조 (이용자의 의무)</b>
                <p>① 이용자는 다음 각 호의 행위를 하여서는 안 됩니다.</p>
                <ul>
                  <li>게시글 등록 시 허위 내용을 등록하는 행위</li>
                  <li>
                    단체 및 제3자의 지적재산권, 저작권을 침해하거나 단체의
                    권리와 업무 또는 제3자의 권리와 활동을 방해하는 행위
                  </li>
                  <li>다른 이용자의 비밀번호를 도용하는 행위</li>
                  <li>
                    관련 법령에 의하여 전송 또는 게시가 금지되는 정보(컴퓨터
                    프로그램, 불법 유해 정보 등)의 게시 또는 전송하는 행위
                  </li>
                  <li>
                    단체의 직원 또는 서비스의 관리자를 가장하거나 타인의 명의를
                    도용하여 정보를 게시, 전송하는 행위
                  </li>
                  <li>
                    컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을
                    방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른
                    컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를
                    게시하거나 전송하는 행위
                  </li>
                  <li>스토킹(stalking) 등 다른 이용자를 괴롭히는 행위</li>
                  <li>
                    다른 이용자 및 제3자에 대한 개인정보를 그 동의 없이 수집,
                    저장, 공개하는 등의 개인정보 침해 행위 및 명예훼손을 하는
                    행위
                  </li>
                  <li>
                    불특정 다수의 자를 대상으로 하여 광고 또는 선전을 게시하거나
                    음란물, 청소년 유해매체물, 불법촬영물 등을 게시하는 행위
                  </li>
                </ul>
                <p>
                  ② 비밀번호가 게시글 비밀번호로 명확하게 제1항에 해당하는
                  행위를 한 이용자가 있을 경우 단체는 이용자의 서비스 이용
                  자격을 적절한 방법으로 제한시킬 수 있습니다. 이용자는 자신의
                  귀책사유로 인하여 단체나 다른 이용자가 입은 손해에 대하여
                  민형사상 책임을 질 수 있습니다.
                </p>
                <p>
                  ③ 이용자는 그 귀책사유로 인하여 단체나 다른 이용자가 입은
                  손해를 배상할 책임이 있습니다.
                </p>
              </div>
              <div>
                <b>제9조 (공개 게시물의 삭제 또는 이용 제한)</b>
                <p>
                  ① 이용자의 공개 게시물의 내용이 다음 각 호에 해당하는 경우
                  단체는 해당 공개 게시물을 무통보 삭제할 수 있습니다.
                </p>
                <ul>
                  <li>
                    다른 이용자 또는 제3자를 비방하거나 중상모략으로 명예를
                    손상시키는 내용
                  </li>
                  <li>
                    음란물, 청소년 유해매체물, 불법촬영물, 욕설 등 공서양속에
                    위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용
                  </li>
                  <li>
                    범죄행위, 불법 유해 정보와 관련이 있다고 판단되는 내용
                  </li>
                  <li>
                    다른 이용자 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
                  </li>
                  <li>
                    종교적, 정치적 분쟁을 야기하는 내용으로서, 이러한 분쟁으로
                    인하여 단체의 업무가 방해되거나 방해되리라고 판단되는 경우
                  </li>
                  <li>
                    타인의 개인정보, 사생활을 침해하거나 명예훼손을 시키는 경우
                  </li>
                  <li>
                    동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에
                    어긋나는 경우
                  </li>
                  <li>
                    불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
                  </li>
                </ul>
                <p>
                  ② 이용자의 공개 게시물로 인한 법률상 이익 침해를 근거로, 다른
                  이용자 또는 제3자가 이용자 또는 단체를 대상으로 하여
                  민형사상의 법적 조치(예: 고소, 가처분 신청,
                  손해배상청구소송)를 취하는 동시에 법적 조치와 관련된 게시물의
                  삭제를 요청해오는 경우, 단체는 동 법적 조치의 결과(예: 검찰의
                  기소, 법원의 가처분 결정, 손해배상 판결)가 있을 때까지 관련
                  게시물에 대한 접근을 잠정적으로 제한할 수 있습니다.
                </p>
              </div>
              <div>
                <b>제10조 (저작권의 귀속 및 게시물의 이용)</b>
                <p>
                  ① 단체가 작성한 저작물에 대한 저작권, 기타 지적재산권은 단체에
                  귀속합니다.
                </p>
                <p>
                  ② 이용자는 단체가 제공하는 서비스를 이용함으로써 얻은 정보를
                  단체의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송, 기타
                  방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게
                  하여서는 안 됩니다.
                </p>
                <p>
                  ③ 이용자가 서비스 내에 게시한 게시물의 저작권은 게시한
                  이용자에게 귀속됩니다. 단, 단체는 서비스의 운영, 전시, 전송,
                  배포, 홍보의 목적으로 이용자의 별도의 허락 없이 무상으로
                  '저작권법'에 규정하는 공정한 관행에 합치되게 합리적인 범위
                  내에서 다음과 같이 이용자가 등록한 게시물을 사용할 수
                  있습니다.
                </p>
                <ul>
                  <li>
                    서비스 내에서 이용자 게시물의 복제, 수정, 개조, 전시, 전송,
                    배포 및 저작물성을 해치지 않는 범위 내에서의 편집 저작물
                    작성
                  </li>
                  <li>
                    미디어, 통신사 등 서비스 제휴 파트너에게 이용자의 게시물
                    내용을 제공, 전시 혹은 홍보하게 하는 것. 단, 이 경우 단체는
                    별도의 동의 없이 이용자의 개인정보를 제공하지 않습니다.
                  </li>
                  <li>
                    단체는 전 항 이외의 방법으로 이용자의 게시물을 이용하고자
                    하는 경우, 전화나 메세지 등의 방법을 통해 사전에 이용자의
                    동의를 얻어야 합니다.
                  </li>
                </ul>
              </div>
              <div>
                <b>제11조 (광고 게재 및 광고주와의 거래)</b>
                <p>
                  ① 단체가 이용자에게 서비스를 제공할 수 있는 서비스 투자기반의
                  일부는 광고 게재를 통한 수익으로부터 나옵니다. 이용자는
                  이용자가 등록한 게시물의 내용을 활용한 광고 게재 및 기타
                  서비스상에 노출되는 광고 게재에 대해 동의합니다.
                </p>
                <p>
                  ② 단체는 서비스상에 게재되어 있거나 서비스를 통한 광고주의
                  판촉활동에 이용자가 참여하거나 교신 또는 거래를 함으로써
                  발생하는 손실과 손해에 대해 책임을 지지 않습니다.
                </p>
              </div>
              <div>
                <b>제12조 (약관의 개정)</b>
                <p>
                  ① 단체는 '정보통신망 이용촉진 및 정보보호 등에 관한 법률',
                  '약관의 규제에 관한 법률' 등 관련 법령을 위배하지 않는
                  범위에서 본 약관을 개정할 수 있습니다.
                </p>
                <p>
                  ② 다만, 개정 내용이 이용자에게 불리할 경우에는 적용일자 14일
                  이전부터 적용일자 전일까지 공지합니다.
                </p>
                <p>
                  ③ 이용자는 변경된 약관에 대해 거부할 권리가 있습니다. 이용자는
                  변경된 약관이 공지된 후 14일 이내에 거부 의사를 표명할 수
                  있습니다. 이용자가 거부하는 경우 단체는 당해 이용자와의
                  이용계약을 해지할 수 있습니다. 만약 이용자가 변경된 약관이
                  공지된 후 14일 이내에 거부 의사를 표하지 않는 경우에는
                  동의하는 것으로 간주합니다.
                </p>
              </div>
              <div>
                <b>제13조 (재판관할)</b>
                <p>
                  ③ 단체와 이용자 간에 발생한 서비스 이용에 관한 분쟁에 대하여는
                  대한민국 법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의
                  관할을 가지는 대한민국의 법원에 제기합니다.
                </p>
              </div>
              <div>
                <b>제14조 (개정 전 고지의무)</b>
                <p>
                  ③ 본 이용약관의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소
                  7일 전에 단체 서비스 내의 메인 페이지를 통하여 사전 공지를 할
                  것입니다.
                </p>
              </div>
            </PolicyContent>
          </div>
        </StyledPolicy>
      </Contents>
    </>
  );
};

const Contents = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: 400px;
  }
`;

const StyledPolicy = styled.div`
  width: 90%;
  margin: 50px auto;
  text-align: start;
  line-height: 1.5;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 10px auto;
    font-size: 14px;
  }
`;

const PolicyContent = styled.div`
  border: 1px solid black;
  padding: 50px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
  box-sizing: border-box;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 10px;
    padding: 30px;
  }
  & > div {
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
  & > div:first-child {
    margin-top: 0px;
  }
  & b {
    font-weight: bold;
  }
  & p {
    font-size: 14px;
    margin: 10px 0;
    line-height: 1.5;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  & ul {
    font-size: 14px;
    margin: 10px 0;
    list-style: decimal;
    list-style-position: inside;
    padding-left: 20px;

    & > li {
      line-height: 1.5;
    }
    @media screen and (max-width: 767px) {
      padding-left: 10px;
      font-size: 12px;
    }
  }
`;

export default Policy;
