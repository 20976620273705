import React from "react";
import styled from "styled-components";
import TitleGradient from "../Components/TitleGradient";
import Sns from "../Components/Sns";

const Cooperate = () => {
  return (
    <>
      <Contents>
        <TitleGradient
          title="모집"
          explain={
            "쿠필과 함께할 수 있는 다양한 제안들을 기다립니다.\n후원을 원하시면 하단의 sns를 통해 연락주세요!"
          }
          link="/recruitment"
          color="linear-gradient(91.48deg, #E5FAFD 0%, rgba(189, 193, 236, 0.31) 100%)"
          title2="제휴"
          link2="/cooperate"
        />
        <Sns />
        <Image>
          <img
            src={
              process.env.REACT_APP_KUPHIL_PUBLIC_URL +
              "/images/cooperate/cooperation1.jpeg"
            }
            alt="KUPHIL 제휴 안내"
          />
          <img
            src={
              process.env.REACT_APP_KUPHIL_PUBLIC_URL +
              "/images/cooperate/cooperation2.jpeg"
            }
            alt="KUPHIL 제휴 안내"
          />
          <img
            src={
              process.env.REACT_APP_KUPHIL_PUBLIC_URL +
              "/images/cooperate/cooperation3.jpeg"
            }
            alt="KUPHIL 제휴 안내"
          />
        </Image>
      </Contents>
    </>
  );
};

const Contents = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    max-width: 400px;
  }
`;

const Image = styled.div`
  width: 100%;
  margin: 40px 0;
  position: relative;
  padding-top: 30%;
  & > img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: none;
    text-align: start;
  }
  & > img:first-child {
    width: 80%;
    display: block;
  }
  @media screen and (max-width: 1023px) {
    padding-top: 35%;
    & > img:first-child {
      width: 90%;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 145%;
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      width: 100%;
      display: block;
    }
    & > img:nth-child(3) {
      display: none;
    }
  }
  @media screen and (max-width: 468px) {
    padding-top: 330%;
    & > img:first-child {
      display: none;
    }
    & > img:nth-child(2) {
      display: none;
    }
    & > img:nth-child(3) {
      width: 60%;
      display: block;
    }
  }
`;

export default Cooperate;
