import React, { useEffect } from "react";
import { useState } from "react";
import styled from 'styled-components';
import { getTipData } from '../../utils/api';

const Tip = () => {
  const [tip, setTip] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    getTip();
  },[]);
  
  const getTip = async () => {
    getTipData()
      .then((res) => {
	console.log(res);
        let newTip = res.data.tip;
        setError(false);
        setTip(newTip);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setTip('');
      })
  };
  const handleReloadTip = () => {
    setError(false);
    setTip('');
    getTip();
  }

  return (
    <StyledTip>
      <Reload onClick={handleReloadTip}>
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2"/></svg>
        <p>새로고침</p>
      </Reload>
      <TipStatement>
        {error ? (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FF0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
            <p style={{color: 'red'}}>오류가 발생했습니다. 새로고침 해주세요.</p>
          </>
        ) : (
          <>{tip}</>
        )}
      </TipStatement>
    </StyledTip>
  );
};
const StyledTip = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Reload = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  // 드래그 방지
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > svg{
    stroke: black;
    padding-right: 3px;
  }
  &:hover{
    cursor: pointer;
    color: #aaaaaa;
    & > svg{
      stroke: #aaaaaa;
    }
  }

  @media screen and (max-width: 767px){
    font-size: 10px;
  }
`;
const TipStatement = styled.div`
  width: 100%;
  padding: 32px 20px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  line-height: 1.2;
  @media screen and (max-width: 767px){
    font-size: 12px;
  }
`;

export default Tip;
